<!--运力 服务商列表 服务商详情 系统设置 复制配置 编辑-->
<template>
  <div>
    <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单信息复制':'订单信息复制'"
      @update:visible="updateVisible">

      <div style="font-size: 14px;font-weight: 400;color: #a8a8a8;margin-top: 10px;">
        选择要复制的字段列表
      </div>

      <el-row :gutter="20" style="margin-top: 10px;">
        <!--<el-col :span="12">-->
        <!--  <div>-->
        <!--    <div style="background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px 6px 0px 0px;padding: 10px 20px 10px 20px;display: flex;justify-content: space-between;">-->
        <!--      <span style="font-size: 18px;font-weight: 500;color: #3f4155;">未选择</span>-->
        <!--      <span style="font-size: 18px;font-weight: 500;color: #3f4155;">-->
        <!--        &lt;!&ndash;{{}}&ndash;&gt;-->
        <!--        /-->
        <!--        {{checkList.length}}-->
        <!--      </span>-->
        <!--    </div>-->
        <!--    <div style="border: 1px solid #e9eaf1;padding: 20px;border-top: none;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">-->
        <!--      <el-row :gutter="15">-->
        <!--        <el-col :span="12" v-for="(item,index) in checkList" :key="index" style="text-align: center;margin-bottom: 15px;">-->
        <!--          <el-checkbox :label="item.label" @click="change(item)"></el-checkbox>-->
        <!--        </el-col>-->
        <!--      </el-row>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--</el-col>-->

        <!--<el-col :span="12">-->

        <!--</el-col>-->
        <div class="transfer">
          <el-transfer v-model="value" :data="checkList" :titles="['未选择', '已选择']"></el-transfer>
        </div>
      </el-row>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {

  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //
      checkList:[
        {
          key:1,
          label:'车牌号',
        },
        {
          key:2,
          label:'案件号',
        },
        {
          key:3,
          label:'订单类型',
        },
        {
          key:4,
          label:'品牌型号',
        },
      ],
      value: [1, 4]

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //
    change(item){
      console.log(item)
    },

  }
}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
