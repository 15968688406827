<!--运力 服务商列表 服务商详情 系统设置 订单分配 订单分配设置-->
<template>
  <div>
    <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单分配设置':'订单分配设置'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        label-width="80px">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="服务项目">
              <el-select
                clearable
                v-model="form.services_type"
                placeholder="请选择"
                class="ele-fluid">
                <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
              </el-select>
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">-->
          <!--  <el-form-item label="服务区域">-->
          <!--    <el-cascader-->
          <!--      ref="refCascader"-->
          <!--      :options="city"-->
          <!--      v-model="form.district"-->
          <!--      @change="handleChange"></el-cascader>-->
          <!--  </el-form-item>-->
          <!--</el-col>-->
        </el-row>

        <div>
          <el-row :gutter="15">
            <el-col :span="12">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 18px;font-weight: 700;color: #3f4155;">部门分配订单比例</span>
              </div>
              <div style="background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;margin-top: 15px;padding: 20px;">
                <div v-for="(item,index) in treeList" :key="index" style="margin-bottom: 10px;">
                  <el-row :gutter="15" style="display: flex;align-items: center;">
                    <el-col :span="22">
                      <el-form-item label-width="110px" :label="item.label" style="margin-bottom: 0!important;">
                        <el-input
                          placeholder="请输入"
                          v-model="item.ratio"
                          clearable>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2" style="text-align: center;">
                      <el-popconfirm
                        title="确定删除吗？"
                        @confirm="del(item,index)"
                      >
                        <img slot="reference" src="@/assets/images/home/yunli/del.png" alt="" style="width: 14px;height: 14px;cursor: pointer;">
                      </el-popconfirm>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" style="margin-top: 10px;" v-for="(it,idx) in item.children" :key="idx">
                    <el-col :span="22">
                      <el-form-item :label="it.label" label-width="250px" style="margin-bottom: 0!important;">
                        <el-input
                          placeholder="请输入"
                          v-model="it.ratio"
                          clearable>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!--<el-row :gutter="15" style="margin-top: 10px;">-->
                  <!--  <el-col :span="22">-->
                  <!--    <el-form-item label="平均分配(%)：" label-width="250px" style="margin-bottom: 0!important;">-->
                  <!--      <el-input-->
                  <!--        placeholder="请输入"-->
                  <!--        v-model="form.name"-->
                  <!--        clearable>-->
                  <!--      </el-input>-->
                  <!--    </el-form-item>-->
                  <!--  </el-col>-->
                  <!--</el-row>-->
                  <!--<el-row :gutter="15" style="margin-top: 10px;">-->
                  <!--  <el-col :span="22">-->
                  <!--    <el-form-item label="订单池  (%)：" label-width="250px">-->
                  <!--      <el-input-->
                  <!--        placeholder="请输入"-->
                  <!--        v-model="form.name"-->
                  <!--        clearable>-->
                  <!--      </el-input>-->
                  <!--    </el-form-item>-->
                  <!--  </el-col>-->
                  <!--</el-row>-->
                </div>

              </div>
            </el-col>

            <el-col :span="12">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 18px;font-weight: 700;color: #3f4155;">选择部门</span>
              </div>
              <div style="background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;padding: 20px;margin-top: 15px;">

                <el-tree
                  ref="tree"
                  :data="dataList"
                  show-checkbox
                  node-key="id"
                  @check="handleCheckChange"
                  @check-change="handleClick"
                  :props="defaultProps">
                </el-tree>
              </div>
            </el-col>

          </el-row>
        </div>

      </el-form>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
//服务区域
import {
  getcityList,
} from '@/api/yunli'
//服务项目接口
import {Addorder_apportion, Editorder_apportion,Detalisorder_apportion,getget_services_available} from "@/api/yunli";
import {get_format} from "@/api/humanResources";
import { formatTreeDept, transListToTreeData} from '@/utils'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    id:Number
  },
  components: {

  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //选择部门
      dataList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },

      //服务项目
      ServicesAvailable:[],
      //城市列表
      city:[],

      //选择的数据
      treeList:[],
      second:[],
    }
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      // this.form = Object.assign({}, this.data);
      Detalisorder_apportion(this.id,this.data.id).then(res => {
        console.log(res)
        this.form = res.data;
        this.treeList = res.data.list;
        this.form.services_type = res.data.items;
        // tree默认绑定的数据
        this.$refs.tree.setCheckedKeys(res.data.dept)
        this.isUpdate = true;
      })
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    // 获取服务项目
    this.getFuwuxiangmu();
    //获取城市列表
    this.getCity();
    //获取部门
    this.getGeshihua();
  },
  methods: {
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    //获取格式化后的部门接口
    getGeshihua(){
      get_format().then(res => {
        console.log(res);
        this.dataList = res.data;
      })
    },

    //树选择
    handleClick(data,checked, node){
      // if(checked){
      //   console.log(data);
      // }
      let list = [];
      let list1 = [];
      console.log(this.$refs.tree.getCheckedNodes());
      this.$refs.tree.getCheckedNodes().forEach(item => {
        if(item.pid == 0){
          list.push(item);
        }else {
          console.log(item);
          list1.push(item);
          JSON.parse(localStorage.getItem('AllcheckedNodes')).forEach(res => {
            if(item.pid == res.id){
              list.push(res);
            }
          });
        }
      })
      // 数组去重
      const map = new Map()
      const qc = list.filter(key => !map.has(key.label) && map.set(key.label, 1)) // 这里对label属性进行去重
      qc.forEach(item1 => {
        if(item1.children != undefined){
          item1.children = list1;
        }
      })
      console.log(qc)
      this.treeList = qc;
    },

    handleCheckChange(datas) {
      // 获取选中的节点和半选中节点
      let AllcheckedNodes = this.$refs.tree.getCheckedNodes(false,true);
      localStorage.setItem('AllcheckedNodes',JSON.stringify(AllcheckedNodes))
      // console.log(AllcheckedNodes)
      // AllcheckedNodes.forEach(item => {
      //   this.second.push(item)
      // })

      // this.second = AllcheckedNodes;
      // console.log(transListToTreeData(this.second,0))
      // this.treeList = this.second;



      this.treeList = formatTreeDept(JSON.parse(localStorage.getItem('AllcheckedNodes')));
      // console.log(this.treeList)
      // console.log(this.dataList)
    },
    //实现单选
    // treeCheck:function(node,list){
    //   //node 该节点所对应的对象、list 树目前的选中状态对象
    //   //选中事件在选中后执行，当lis中有两个选中时，使用setCheckedKeys方法，选中一个节点
    //   if (list.checkedKeys.length == 2) {
    //     //单选实现
    //     this.$refs.tree.setCheckedKeys([node.id]);
    //   }
    // },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            this.form.details = this.treeList;
            Addorder_apportion(this.id,this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editorder_apportion(this.id,data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //部门分配订单比例删除
    del(item,index){
      console.log('点击了删除')
      this.treeList.splice(index,1);
    },

    handleChange(value) {
      console.log(value);
    }


  }
}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
