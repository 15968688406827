<template>
  <div>

    <div style="display: flex;align-items: center;margin-top: 20px;">
      <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
      <span style="font-size: 20px;font-weight: 700;color: #202033;">电子工单通用模板</span>
      <span style="width: 134px;background: #53d1a1;border-radius: 6px;padding-top: 8px;padding-bottom: 8px;font-size: 14px;font-weight: 400;color: #ffffff;display: inline-block;text-align: center;cursor: pointer;margin-left: 20px;" @click="To">支付XXX元开通</span>
    </div>
    <div style="margin-top: 10px; text-align: center;">
      <div style="margin-bottom: 20px;">
        <span style="font-size: 20px;font-weight: 700;color: #202033;">道路救援服务工单</span>
      </div>

      <!-- 表格 -->
      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center">报案时间</td>
          <td>2023年09月03日 16：36：19</td>
          <td style="width: 10%;text-align: center">电子单号</td>
          <td>CIC20230909163919652221</td>
          <td style="width: 10%;text-align: center">报案号后6位</td>
          <td>639729</td>
        </tr>
        <tr>
          <td style="width: 10%;text-align: center">客户姓名</td>
          <td>李艳辉</td>
          <td style="width: 10%;text-align: center">客户电话</td>
          <td>18233522236</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="width: 10%;text-align: center">车牌号码</td>
          <td>冀C9281B</td>
          <td style="width: 10%;text-align: center">车架后6位</td>
          <td>307218</td>
          <td style="width: 10%;text-align: center">品牌车型</td>
          <td>宝骏LZW7128KFA轿车</td>
        </tr>
        <tr>
          <td style="width: 10%;text-align: center">派工时间</td>
          <td></td>
          <td style="width: 10%;text-align: center">到场时间</td>
          <td>2023年09月09日 17:16:55</td>
          <td style="width: 10%;text-align: center">完成时间</td>
          <td>2023年09月09日 17:16:55</td>
        </tr>
      </table>

      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center; border-top: none;">业务来源</td>
          <td style="width: 90%;border-top: none;">一键救援</td>
        </tr>
      </table>
      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center; border-top: none;">救援地点</td>
          <td style="width: 90%;border-top: none;">河北省秦皇岛市海港区惠民街与南岭路交叉口正西方向238米富立秦皇半岛7区</td>
        </tr>
      </table>
      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center; border-top: none;">目的地点</td>
          <td style="width: 90%;border-top: none;">河北省秦皇岛市海港区经济技术开发区渤海西道3号路能达(秦皇岛)国际汽车文化创意产业园</td>
        </tr>
      </table>

      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center;border-top: none;">服务项目</td>
          <td style="border-top: none;">拖车救援</td>
          <td style="width: 10%;text-align: center;border-top: none;">处理结果</td>
          <td style="border-top: none;">完成</td>
        </tr>
        <tr>
          <td style="width: 10%;text-align: center;border-top: none;">服务人员</td>
          <td style="border-top: none;">卢占林</td>
          <td style="width: 10%;text-align: center;border-top: none;">服务车牌</td>
          <td style="border-top: none;">京KHA468</td>
        </tr>
        <tr>
          <td style="width: 10%;text-align: center;border-top: none;">前往救援地</td>
          <td style="border-top: none;">4.00公里</td>
          <td style="width: 10%;text-align: center;border-top: none;">拖车里程数</td>
          <td style="border-top: none;">7.00公里</td>
        </tr>
      </table>

      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center; border-top: none;">轨迹</td>
          <td style="width: 90%;border-top: none;">
            <img src="@/assets/images/home/map.png" alt="" style="width: 100%;height: 300px;">
          </td>
        </tr>
      </table>

      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center;border-top: none;">客户签字</td>
          <td style="border-top: none;"></td>
          <td style="width: 10%;text-align: center;border-top: none;">接车人签字</td>
          <td style="border-top: none;"></td>
        </tr>
      </table>

      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center; border-top: none;">司机签字</td>
          <td style="width: 90%;border-top: none;"></td>
        </tr>
      </table>

      <table class="table">
        <tr>
          <td style="width: 10%;text-align: center; border-top: none;">客户声明</td>
          <td style="width: 90%;border-top: none;">1.在救援前请客户与救援服务人检查确认好车身情况，如客户隐瞒相关情况造成的损失由客户自行承担，因此给服务人造成损失，客户需进行赔偿2.敦据过程中，建议客户将车内一切贵重物品携带保管好，以免发生纠纷，若发生丢失、毁坏，本司不承担任何责任3.救援过程中，严禁客户坐在故车内，货运车辆严禁载货托运，我司不对此承担任何责任4.救援完成后，客户或交车人需确认服务完成及车辆无损，若有受损需在救援服务单签字前及时向我司反馈，签字后视为服务已完成，我司不再对索赔承担任何责任。5.困境，吊车免责声明:在发生事故拖车，吊车，困境施救过程中所产生的二次损伤与救援公司无关，</td>
        </tr>
      </table>

    </div>

    <div style="margin-top: 20px;display: flex;align-items: center;">
      <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
      <span style="font-size: 20px;font-weight: 700;color: #202033;">用户端免责条款</span>
    </div>
    <div>
      <div style="border: 1px solid #ccc;">
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 500px; overflow-y: hidden;"
          v-model="html"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
        />
      </div>
      <div style="margin-top: 20px;text-align: center;">
        <el-button>取消</el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          @click="save()">保存
        </el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  export default {
    props:{
      id:Number
    },
    components: { Editor, Toolbar },
    data(){
      return{
        editor: null,
        html: '',
        toolbarConfig: { },
        editorConfig: { placeholder: '请输入内容...' },
        mode: 'default', // or 'simple'
      }
    },

    methods:{
      //跳转到支付页面
      To(){
        this.$router.push('/PublicPage/ActivateService')
      },

      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },

      save(){
        console.log(this.html);
      },


    },

    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    }


  }
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.table{
  width: 100%;
  text-align: left;
  table-layout: fixed;
  border-collapse: collapse;
  td{
    border: 1px solid #1e1e1e;
    padding: 5px;
    margin: 0;
  }
}
</style>
