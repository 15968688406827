<template>
  <!--企业钱包模块-->
  <div>
    <el-row :gutter="15">
      <el-col :span="24">
        <el-card shadow="never">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-row
                :gutter="15"
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <!-- <el-col :span="8">
                  <span
                    style="font-size: 16px; font-weight: 400; color: #7a7a7a"
                    >可提现余额</span
                  >
                </el-col> -->
                <!-- <el-col :span="6">
                  <span
                    style="font-size: 20px; font-weight: 700; color: #ff9b05"
                    >{{ firm_wallet.money }}</span
                  >
                </el-col> -->
                <el-col :span="10">
                  <!--<span style="border: 1px solid #c5c6cc;border-radius: 4px;display: inline-block;padding: 5px 10px 5px 10px;cursor: pointer;" @click="TowithdrawalApplication">-->
                  <!--  <span style="font-size: 16px;font-weight: 400;color: #ff9b05;">提现</span>-->
                  <!--</span>-->
                </el-col>
              </el-row>
              <el-row
                :gutter="15"
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <el-col :span="8">
                  <span
                    style="font-size: 16px; font-weight: 400; color: #7a7a7a"
                    >运营钱包余额</span
                  >
                </el-col>
                <el-col :span="6">
                  <span
                    style="font-size: 20px; font-weight: 700; color: #53d1a1"
                    >{{ firm_wallet.operation_money }}元</span
                  >
                </el-col>
                <el-col :span="10">
                  <!--<span style="border: 1px solid #c5c6cc;border-radius: 4px;display: inline-block;padding: 5px 10px 5px 10px;cursor: pointer;" @click="openshowrecharge()">-->
                  <!--  <span style="font-size: 16px;font-weight: 400;color: #53D1A1;">充值</span>-->
                  <!--</span>-->
                </el-col>
              </el-row>
              <!-- <el-row
                :gutter="15"
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <el-col :span="8">
                  <span
                    style="font-size: 16px; font-weight: 400; color: #7a7a7a"
                    >可开票金额</span
                  >
                </el-col>
                <el-col :span="6">
                  <span
                    style="font-size: 20px; font-weight: 700; color: #e67471"
                    >{{ firm_wallet.invoice_money }}</span
                  >
                </el-col>
                <el-col :span="10">
                  <!--<span style="border: 1px solid #c5c6cc;border-radius: 4px;display: inline-block;padding: 5px 10px 5px 10px;cursor: pointer;" @click="openinvoicingApplication">-->
              <!--  <span style="font-size: 16px;font-weight: 400;color: #E67471;">开票申请</span>-->
              <!--</span>-->
              <!-- </el-col>
              </el-row> -->
              <el-row
                :gutter="15"
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <el-col :span="5">
                  <span
                    style="font-size: 16px; font-weight: 400; color: #7a7a7a"
                    >购买服务类型次数</span
                  >
                </el-col>
                <el-col :span="6">
                  <span
                    style="font-size: 20px; font-weight: 700; color: #3f4155"
                    >{{ firm_wallet.service_margin }}次</span
                  >
                </el-col>
                <el-col :span="10">
                  <!--<span style="border: 1px solid #c5c6cc;border-radius: 4px;display: inline-block;padding: 5px 10px 5px 10px;cursor: pointer;" @click="TocommodityPurchase">-->
                  <!--  <span style="font-size: 16px;font-weight: 400;color: #3F4155;">购买</span>-->
                  <!--</span>-->
                  <!--<span style="border: 1px solid #c5c6cc;border-radius: 4px;display: inline-block;padding: 5px 10px 5px 10px;cursor: pointer;margin-left: 20px;" @click="TopackagePurchaseRecord">-->
                  <!--  <span style="font-size: 16px;font-weight: 400;color: #3F4155;">查看记录</span>-->
                  <!--</span>-->
                </el-col>
              </el-row>
              <el-row
                :gutter="15"
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <el-col :span="8">
                  <span
                    style="font-size: 16px; font-weight: 400; color: #7a7a7a"
                    >隐私号码数量</span
                  >
                </el-col>
                <el-col :span="6">
                  <span
                    style="font-size: 20px; font-weight: 700; color: #3f4155"
                    >{{ firm_wallet.virtual_number }}个</span
                  >
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="6">
              <el-row
                :gutter="15"
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <el-col :span="9">
                  <span
                    style="font-size: 16px; font-weight: 400; color: #7a7a7a"
                    >网络电话分钟数</span
                  >
                </el-col>
                <el-col :span="7">
                  <span
                    style="font-size: 20px; font-weight: 700; color: #3f4155"
                    >{{ firm_wallet.phone_minute }}分钟</span
                  >
                </el-col>
                <el-col :span="8"> </el-col>
              </el-row>

              <el-row
                :gutter="15"
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <el-col :span="9">
                  <span
                    style="font-size: 16px; font-weight: 400; color: #7a7a7a"
                    >语音通知分钟数</span
                  >
                </el-col>
                <el-col :span="7">
                  <span
                    style="font-size: 20px; font-weight: 700; color: #3f4155"
                    >{{ firm_wallet.phone_minute }}分钟</span
                  >
                </el-col>
                <el-col :span="8"> </el-col>
              </el-row>
            </el-col>

            <el-col :span="6" v-if="
                      firm_wallet.invitation_qrcode &&
                      firm_wallet.invitation_qrcode != ''
                    ">
              <el-row
                :gutter="15"
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <el-col :span="6">
                  <span
                    style="font-size: 16px; font-weight: 400; color: #7a7a7a"
                    >企业推广码</span
                  >
                </el-col>
                <el-col :span="8">   
                    <img
                      :src="firm_wallet.invitation_qrcode"
                      alt=""
                      style="width: 100px; height: 100px; border-radius: 4px"
                    />
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </el-col>

      <!-- <el-col :span="6">
        <el-card shadow="never" style="padding-top: 8px !important;padding-bottom: 15px;">
          <div style="width: 100%;padding:10px; background: linear-gradient(101deg,#ffe5ca 8%, #ffffff 94%);border: 1px solid #ffcc96;border-radius: 11px;display: flex;align-items: center;justify-content: space-between;cursor: pointer;margin-bottom: 20px;">
            <div style="display: flex;align-items: center;">
              <div>
                <img src="@/assets/images/home/yunli/pnhone.png" alt="" style="width: 49px;height: 49px;">
              </div>
              <div style="margin-left: 15px;">
                <div style="font-size: 14px;font-weight: 400;color: #3f4155;">拨打电话</div>
                <div style="font-size: 18px;font-weight: 700;color: #FE8C25;">余20分钟</div>
              </div>
            </div>
            <div>
              <img src="@/assets/images/home/yunli/goumai1.png" alt="" style="margin-top: 3px;" @click="openpurchase">
            </div>
          </div>
          <div style="width: 100%;padding:10px; background: linear-gradient(101deg,#dbf9ee 8%, #ffffff 94%);border: 1px solid #a2eac6;border-radius: 11px;display: flex;align-items: center;justify-content: space-between;cursor: pointer;margin-bottom: 20px;">
            <div style="display: flex;align-items: center;">
              <div>
                <img src="@/assets/images/home/yunli/duanxin.png" alt="" style="width: 49px;height: 49px;">
              </div>
              <div style="margin-left: 15px;">
                <div style="font-size: 14px;font-weight: 400;color: #3f4155;">发送短信</div>
                <div style="font-size: 18px;font-weight: 700;color: #36DD9E;">余20分钟</div>
              </div>
            </div>
            <div>
              <img src="@/assets/images/home/yunli/goumai2.png" alt="" style="margin-top: 3px;" @click="openpurchase">
            </div>
          </div>
          <div style="width: 100%;padding:10px; background: linear-gradient(101deg,#e2f0ff 8%, #ffffff 94%);border: 1px solid #add0f6;border-radius: 11px;display: flex;align-items: center;justify-content: space-between;cursor: pointer;">
            <div style="display: flex;align-items: center;">
              <div>
                <img src="@/assets/images/home/yunli/dianhua.png" alt="" style="width: 49px;height: 49px;">
              </div>
              <div style="margin-left: 15px;">
                <div style="font-size: 14px;font-weight: 400;color: #3f4155;">自动语音电话</div>
                <div style="font-size: 18px;font-weight: 700;color: #2E91F0;">余200分钟</div>
              </div>
            </div>
            <div>
              <img src="@/assets/images/home/yunli/goumai3.png" alt="" style="margin-top: 3px;" @click="openpurchase">
            </div>
          </div>
        </el-card>
      </el-col> -->
    </el-row>

    <!--收支明细-->
    <el-row style="margin-top: 20px">
      <el-card shadow="never">
        <div class="where">
          <el-form
            :model="where"
            label-width="90px"
            class="ele-form-search"
            @submit.native.prevent
          >
            <el-row :gutter="15">
              <el-col :span="12">
                <div style="display: flex; align-items: center">
                  <span
                    style="
                      width: 5px;
                      height: 19px;
                      background: #ff9b05;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span
                    style="font-size: 20px; font-weight: 700; color: #2c2e46"
                    >收支明细</span
                  >
                </div>
              </el-col>
              <!-- <el-col :span="12">
                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-input
                      clearable
                      placeholder="订单号"
                      v-model="where.odd"
                    >
                      <i
                        slot="prefix"
                        class="el-input__icon el-icon-search"
                      ></i>
                    </el-input>
                  </el-col>
                  <el-col :span="12" style="display: flex; align-items: center">
                    <el-select
                      clearable
                      v-model="where.pm"
                      placeholder="请选择类型"
                      class="ele-fluid"
                    >
                      <el-option label="收入" value="0" />
                      <el-option label="支出" value="1" />
                    </el-select>
                    <el-button style="margin-left: 10px" @click="search"
                      >搜索</el-button
                    >
                  </el-col>
                </el-row>
              </el-col> -->
            </el-row>
          </el-form>
        </div>

        <!--数据表格-->
        <div class="dataList" style="margin-top: 20px">
          <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :total="total"
            :customsFromWhere="where"
            :loading="loading"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
          >
          </my-table>
        </div>
      </el-card>
    </el-row>

    <!--开票记录-->
    <el-row style="margin-top: 20px">
      <el-card shadow="never">
        <div class="where">
          <el-form
            :model="where1"
            label-width="90px"
            class="ele-form-search"
            @submit.native.prevent
          >
            <el-row :gutter="15">
              <el-col :span="18">
                <div style="display: flex; align-items: center">
                  <span
                    style="
                      width: 5px;
                      height: 19px;
                      background: #ff9b05;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span
                    style="font-size: 20px; font-weight: 700; color: #2c2e46"
                    >开票记录</span
                  >
                </div>
              </el-col>
              <!-- <el-col :span="6">
                <el-row :gutter="15">
                  <el-col :span="12">
                    <!--占位-->
              <!-- <span
                      style="display: inline-block; width: 10px; height: 10px"
                    ></span>
                  </el-col>
                  <el-col :span="12" style="display: flex; align-items: center">
                    <el-select
                      clearable
                      v-model="where1.status"
                      placeholder="请选择类型"
                      class="ele-fluid"
                    >
                      <el-option label="未开票" value="0" />
                      <el-option label="已开票" value="1" />
                    </el-select>
                    <el-button style="margin-left: 10px" @click="search1"
                      >搜索</el-button
                    >
                  </el-col>
                </el-row>
              </el-col> -->
            </el-row>
          </el-form>
        </div>

        <!--数据表格-->
        <div class="dataList" style="margin-top: 20px">
          <my-table
            ref="myTable"
            :columns="columns1"
            :tableData="List1"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :total="total1"
            :customsFromWhere="where1"
            :loading="loading1"
            @select="select1"
            @ChangeSize="ChangeSize1"
            @currentChange="currentChange1"
          >
            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot="{ scope }">
              <el-link
                :underline="false"
                type="warning"
                @click="download(scope.row)"
                >点击下载</el-link
              >
              <!--<el-link :underline="false" type="info">删除</el-link>-->
            </template>
          </my-table>
        </div>
      </el-card>
    </el-row>

    <!--提现记录-->
    <!-- <el-row style="margin-top: 20px">
      <el-card shadow="never">
        <div class="where">
          <el-form
            :model="where2"
            label-width="90px"
            class="ele-form-search"
            @submit.native.prevent
          >
            <el-row :gutter="15">
              <el-col :span="18">
                <div style="display: flex; align-items: center">
                  <span
                    style="
                      width: 5px;
                      height: 19px;
                      background: #ff9b05;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span
                    style="font-size: 20px; font-weight: 700; color: #2c2e46"
                    >提现记录</span
                  >
                </div>
              </el-col>
              <el-col :span="6">
                <el-row :gutter="15">
                  <el-col :span="12">
                    <!--占位-->
    <!-- <span
                      style="display: inline-block; width: 10px; height: 10px"
                    ></span>
                  </el-col>
                  <el-col :span="12" style="display: flex; align-items: center">
                    <el-select
                      clearable
                      v-model="where2.status"
                      placeholder="请选择类型"
                      class="ele-fluid"
                    >
                      <el-option label="未通过" value="-1" />
                      <el-option label="审核中" value="0" />
                      <el-option label="已提现" value="1" />
                    </el-select>
                    <el-button style="margin-left: 10px" @click="search2"
                      >搜索</el-button
                    >
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form>
        </div> -->

    <!--提现记录-->
    <!-- <div class="dataList" style="margin-top: 20px">
          <my-table
            ref="myTable"
            :columns="columns2"
            :tableData="List2"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :total="total2"
            :customsFromWhere="where2"
            :loading="loading2"
            @select="select2"
            @ChangeSize="ChangeSize2"
            @currentChange="currentChange2"
          >
          </my-table>
        </div>
      </el-card>
    </el-row>  -->

    <!--更换绑定-->
    <BangDing :data="current" :visible.sync="showEdit" />

    <!--充值弹窗-->
    <recharge-edit :data="rechargecurrent" :visible.sync="showrecharge" />

    <!--开票申请弹窗-->
    <invoicingApplication-edit
      :data="invoicingApplicationcurrent"
      :visible.sync="showinvoicingApplication"
    />

    <!--购买弹窗-->
    <purchase-edit :data="purchasecurrent" :visible.sync="showpurchase" />
  </div>
</template>

<script>
//引入更换绑定
import BangDing from "./components/binding-edit.vue";
//引入充值弹窗
import RechargeEdit from "./components/recharge-edit.vue";
//引入开票申请
import InvoicingApplicationEdit from "./components/invoicingApplication-edit.vue";
//引入购买
import PurchaseEdit from "./components/purchase-edit.vue";

// 权限
import { ShowTable } from "@/utils";

// 引入的接口
import {
  balance_payments,
  extract_log,
  firm_wallet,
  invoice_log,
} from "@/api/yunli";

export default {
  props: {
    id: Number,
  },
  components: {
    BangDing,
    RechargeEdit,
    InvoicingApplicationEdit,
    PurchaseEdit,
  },
  data() {
    return {
      // 搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "时间",
          // type: "index",
          // width: "70"
          prop: "add_time",
          isShow: true,
        },
        {
          label: "收支类型",
          prop: "pm",
          isShow: true,
        },
        {
          label: "订单编号",
          prop: "odd",
          isShow: true,
        },
        {
          label: "服务类型",
          prop: "items_name",
          isShow: true,
        },
        {
          label: "订单金额",
          prop: "order_money",
          isShow: true,
        },
        {
          label: "信息费",
          prop: "message_fee",
          isShow: true,
        },
        {
          label: "保险费",
          prop: "premium",
          isShow: true,
        },
        {
          label: "到账金额",
          prop: "number",
          isShow: true,
        },
      ],

      // 开票记录表格搜索条件
      where1: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List1: [],
      // 总条目数
      total1: 0,
      //加载动画
      loading1: false,
      // 表头数据
      columns1: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "时间",
          // type: "index",
          // width: "70"
          prop: "time",
          isShow: true,
        },
        {
          label: "状态",
          prop: "status",
          isShow: true,
        },
        {
          label: "申请编号",
          prop: "invoice_number",
          isShow: true,
        },
        {
          label: "开票金额",
          prop: "invoice_member",
          isShow: true,
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true,
          fixed: "right",
        },
      ],

      // 提现记录表格搜索条件
      where2: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List2: [],
      // 总条目数
      total2: 0,
      //加载动画
      loading2: false,
      // 表头数据
      columns2: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "提现单号",
          // type: "index",
          // width: "70"
          prop: "odd",
          isShow: true,
        },
        {
          label: "提现申请时间",
          prop: "withdraw_time",
          isShow: true,
        },
        {
          label: "提现金额",
          prop: "extract_price",
          isShow: true,
        },
        {
          label: "提现到账时间",
          prop: "accounting_time",
          isShow: true,
        },
        {
          label: "提现类型",
          prop: "extract_type",
          isShow: true,
        },
        {
          label: "状态",
          prop: "status",
          isShow: true,
        },
      ],

      //更改绑定弹窗
      current: null,
      showEdit: false,

      //充值弹窗
      rechargecurrent: null,
      showrecharge: false,

      //开票申请
      invoicingApplicationcurrent: null,
      showinvoicingApplication: false,

      //购买弹窗
      purchasecurrent: null,
      showpurchase: false,

      //企业钱包基本信息
      firm_wallet: {},
    };
  },

  mounted() {
    // 调用获取企业钱包基本信息接口
    this.getfirm_wallet();
    // 调用获取企业钱包收支明细
    this.getList();
    // 调用获取开票记录接口
    this.getinvoice_log();
    // 调用获取提现记录
    // this.getextract_log();
  },

  methods: {
    //获取企业钱包基本信息接口
    getfirm_wallet() {
      firm_wallet(this.id).then((res) => {
        this.firm_wallet = res.data;
      });
    },

    // 点击更改绑定
    openEdit() {
      this.current = null;
      this.showEdit = true;
    },

    //点击充值
    openshowrecharge() {
      this.rechargecurrent = null;
      this.showrecharge = true;
    },

    //点击开票申请
    openinvoicingApplication() {
      this.invoicingApplicationcurrent = null;
      this.showinvoicingApplication = true;
    },

    //点击购买
    openpurchase() {
      this.purchasecurrent = false;
      this.showpurchase = true;
    },

    // 获取企业钱包收支明细
    getList() {
      balance_payments(this.id, this.where)
        .then((res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        })
        .catch((err) => {
          this.$Message.error(err);
          this.loading = false;
        });
    },
    // 收支明细搜索
    search() {
      this.getList();
    },
    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val);
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize(val) {
      console.log("当前页面显示的条数", val);
      this.where.limit = val;
      this.getList();
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false;
      } else {
        return true;
      }
    },

    // 开票记录表格事件
    getinvoice_log() {
      invoice_log(this.id, this.where1)
        .then((res) => {
          this.total1 = res.data.count;
          this.List1 = res.data.list;
          this.loading1 = false;
        })
        .catch((err) => {
          this.$Message.error(err);
          this.loading1 = false;
        });
    },
    // 搜索
    search1() {
      this.getinvoice_log();
    },
    // 当前页数改变事件
    currentChange1(val) {
      console.log("当前页数", val);
      this.where1.page = val;
      this.getinvoice_log();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select1(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize1(val) {
      console.log("当前页面显示的条数", val);
      this.where1.limit = val;
      this.getinvoice_log();
    },
    //点击下载
    download(row) {
      console.log(row);
      window.open(row.invoice_picture);
      // downCode('https://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/driving_licence/20231013/facilitator-1/3e8ed202310131749253344.jpg')
      // window.open('https://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/driving_licence/20231013/facilitator-1/3e8ed202310131749253344.jpg');

      // 下载xlsx
      // this.download('system/user/importTemplate', {
      // }, `user_template_${new Date().getTime()}.xlsx`)
    },

    // 获取提现记录
    // getextract_log() {
    //   extract_log(this.id, this.where2)
    //     .then((res) => {
    //       this.total2 = res.data.count;
    //       this.List2 = res.data.list;
    //       this.loading2 = false;
    //     })
    //     .catch((err) => {
    //       this.$Message.error(err);
    //       this.loading2 = false;
    //     });
    // },
    // search2() {
    //   this.getextract_log();
    // },
    //提现记录表格事件
    // 当前页数改变事件
    currentChange2(val) {
      console.log("当前页数", val);
      this.where2.page = val;
      this.getextract_log();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select2(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize2(val) {
      console.log("当前页面显示的条数", val);
      this.where2.limit = val;
      this.getextract_log();
    },

    //点击购买按钮
    Togoumai() {
      this.$router.push("/ActivateService");
    },
    //点击提现按钮
    TowithdrawalApplication() {
      this.$router.push("/withdrawalApplication");
    },
    //点击购置服务余量的购买按钮
    TocommodityPurchase() {
      this.$router.push("/commodityPurchase");
    },

    //点击查看记录
    TopackagePurchaseRecord() {
      this.$router.push("/packagePurchaseRecord");
    },

    //点击管理
    ToshoppingMallProductList() {
      this.$router.push("/shoppingMallProductList");
    },

    //点击垫资合格查看按钮
    ToinvestmentRecordList() {
      this.$router.push("/investmentRecordList");
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
}
.dataList {
  margin-top: 10px;
}
</style>
