<!-- 审核设置弹窗 -->
<template>
  <el-dialog
    width="60%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'修改审核设置':'审核设置'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="140px">

      <el-row :gutter="15">
        <el-col :span="8">
          <el-form-item label="服务项目" label-width="100px">
            <el-select
              clearable
              v-model="form.services_available"
              placeholder="请选择"
              class="ele-fluid">
              <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="自动确认周期(小时)" prop="">
            <el-input
              clearable
              :maxlength="20"
              v-model="form.automatic_period"
              placeholder="请输入"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="补传照片周期(小时)" prop="">
            <el-input
              clearable
              :maxlength="20"
              v-model="form.return_pass_period"
              placeholder="请输入"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="15">
        <div class="checkboxziidngyi">
          <div style="display: inline-block;cursor: pointer;" @click="active()">
            <img v-if="form.auto_affirm.switch == false" src="@/assets/images/home/yunli/weixuanzhong.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
            <img v-else src="@/assets/images/home/yunli/yixuanzhong.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;vertical-align: middle;margin-left: 10px;">{{form.auto_affirm.name}}</span>
          </div>
          <span style="font-size: 14px;font-weight: 400;color: #4e4e4e;margin-left: 40px;">{{form.auto_affirm.explain}}</span>
        </div>
        <div style="background: #f7f8fa;border: 1px solid #e3e4e9;border-radius: 6px;margin-top: 20px;padding: 20px;">
          <el-checkbox v-for="(item,index) in form.auto_affirm.data" :key="index" v-model="item.switch" :label="item.label"></el-checkbox>
        </div>
      </el-row>

      <el-row :gutter="15" style="margin-top: 20px;">
        <div class="checkboxziidngyi">
          <div style="display: inline-block;cursor: pointer;" @click="automaticReviewactive()">
            <img v-if="form.order_auto_audit.switch == false" src="@/assets/images/home/yunli/weixuanzhong.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
            <img v-else src="@/assets/images/home/yunli/yixuanzhong.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;vertical-align: middle;margin-left: 10px;">{{form.order_auto_audit.name}}</span>
          </div>
        </div>
        <div style="background: #f7f8fa;border: 1px solid #e3e4e9;border-radius: 6px;margin-top: 20px;padding: 20px;">
          <el-row :gutter="15">
            <el-col :span="6" v-for="(item,index) in form.order_auto_audit.data.input" :key="index">
              <el-form-item :label="item.label" prop="" label-width="150px">
                <el-input
                  clearable
                  :maxlength="20"
                  v-model="item.value"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
          </el-row>
          <div>
            <el-checkbox v-for="(item,index) in form.order_auto_audit.data.checkbox" :key="index" v-model="item.switch" :label="item.label"></el-checkbox>
          </div>
        </div>
      </el-row>

      <el-row :gutter="15" style="margin-top: 20px;">
        <div class="checkboxziidngyi">
          <div style="display: inline-block;cursor: pointer;" @click="kongshiactive()">
            <img v-if="form.empty_auto_audit.switch == false" src="@/assets/images/home/yunli/weixuanzhong.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
            <img v-else src="@/assets/images/home/yunli/yixuanzhong.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;vertical-align: middle;margin-left: 10px;">{{form.empty_auto_audit.name}}</span>
          </div>
        </div>
        <div style="background: #f7f8fa;border: 1px solid #e3e4e9;border-radius: 6px;margin-top: 20px;padding: 20px;">
          <el-row :gutter="15">
            <el-col :span="6" v-for="(item,index) in form.empty_auto_audit.data.input" :key="index">
              <el-form-item :label="item.label" prop="" label-width="150px">
                <el-input
                  clearable
                  :maxlength="20"
                  v-model="item.value"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="照片数量(≥张)：" prop="">-->
            <!--    <el-input-->
            <!--      clearable-->
            <!--      :maxlength="20"-->
            <!--      v-model="form.nickname"-->
            <!--      placeholder="请输入"/>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="放空里程(≤公里)：" prop="">-->
            <!--    <el-input-->
            <!--      clearable-->
            <!--      :maxlength="20"-->
            <!--      v-model="form.nickname"-->
            <!--      placeholder="请输入"/>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
          </el-row>
          <div>
            <el-checkbox v-for="(item,index) in form.empty_auto_audit.data.checkbox" :key="index" v-model="item.switch" :label="item.label"></el-checkbox>
          </div>
        </div>
      </el-row>

      <el-row :gutter="15" style="margin-top: 20px;">
        <div class="checkboxziidngyi">
          <div style="display: inline-block;cursor: pointer;" @click="quxiaoactive()">
            <img v-if="form.cancellation_order.switch == false" src="@/assets/images/home/yunli/weixuanzhong.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
            <img v-else src="@/assets/images/home/yunli/yixuanzhong.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;">
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;vertical-align: middle;margin-left: 10px;">{{form.cancellation_order.name}}</span>
          </div>
        </div>
        <div style="background: #f7f8fa;border: 1px solid #e3e4e9;border-radius: 6px;margin-top: 20px;padding: 20px 20px 0 20px;">
          <el-row :gutter="15">
            <el-col :span="6" v-for="(item,index) in form.cancellation_order.data" :key="index">
              <el-form-item :label="item.label" prop="" label-width="150px">
                <el-input
                  clearable
                  :maxlength="20"
                  v-model="item.value"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="照片数量(≥张)：" prop="">-->
            <!--    <el-input-->
            <!--      clearable-->
            <!--      :maxlength="20"-->
            <!--      v-model="form.nickname"-->
            <!--      placeholder="请输入"/>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="放空里程(≤公里)：" prop="">-->
            <!--    <el-input-->
            <!--      clearable-->
            <!--      :maxlength="20"-->
            <!--      v-model="form.nickname"-->
            <!--      placeholder="请输入"/>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
          </el-row>
        </div>
      </el-row>

      <!--审核合格补扣款项-->
      <el-row :gutter="15" style="margin-top: 20px;">
        <div style="display: flex;justify-content: space-between;">
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;">审核合格补扣款项</span>
          </div>
          <div>
            <el-button style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">
              <i class="el-icon-tianjia" />
              <span style="vertical-align: middle" @click="add()">添加</span>
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div style="margin-top: 20px;" class="shurukuang">
          <el-row style="text-align: center;background: #F7F8FA;">
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              补扣款项
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              模式
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 9px;padding-bottom:0">
               <span>
                 补扣款金额 <br/>
                  <span style="font-size: 10px;font-weight: 400;color: #7e7f8c;">正数为补款，负数为扣款</span>
               </span>
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              操作
            </el-col>
          </el-row>
          <el-row style="text-align: center;display: flex;" v-for="(item,index) in form.assist.pass" :key="index">
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              <el-link v-if="item.biaoshi != 0" :underline="false" type="info">{{ item.name }}</el-link>
              <el-input
                v-else
                placeholder="请输入内容"
                v-model="item.name"
                clearable>
              </el-input>
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              <el-link v-if="item.biaoshi != 0" :underline="false" type="info">{{ item.mode }}</el-link>
              <el-select
                v-else
                clearable
                v-model="item.mode"
                placeholder="请选择"
                class="ele-fluid">
                <el-option label="总价比例%" value="proportion"/>
                <el-option label="固定金额元" value="immobilization"/>
              </el-select>
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              <el-link v-if="item.biaoshi != 0" :underline="false" type="info">{{ item.figure }}</el-link>
              <el-input
                v-else
                placeholder="请输入内容"
                v-model="item.figure"
                clearable>
              </el-input>
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              <el-link :underline="false" type="warning" v-if="item.biaoshi == 0" @click="preservation(item,index)">保存</el-link>
              <el-link :underline="false" type="info" v-else @click="del(item,index)">删除</el-link>
            </el-col>
          </el-row>
        </div>
      </el-row>

      <!--审核不合格补扣款项-->
      <el-row :gutter="15" style="margin-top: 20px;">
        <div style="display: flex;justify-content: space-between;">
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;">审核不合格补扣款项</span>
          </div>
          <div>
            <el-button style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">
              <i class="el-icon-tianjia" />
              <span style="vertical-align: middle" @click="buadd()">添加</span>
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div style="margin-top: 20px;" class="shurukuang">
          <el-row style="text-align: center;background: #F7F8FA;">
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              补扣款项
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              模式
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 9px;padding-bottom:0">
               <span>
                 补扣款金额 <br/>
                  <span style="font-size: 10px;font-weight: 400;color: #7e7f8c;">正数为补款，负数为扣款</span>
               </span>
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              操作
            </el-col>
          </el-row>
          <el-row style="text-align: center;display: flex;" v-for="(item,index) in form.assist.flunk" :key="index">
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              <el-link v-if="item.biaoshi != 0" :underline="false" type="info">{{ item.name }}</el-link>
              <el-input
                v-else
                placeholder="请输入内容"
                v-model="item.name"
                clearable>
              </el-input>
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              <el-link v-if="item.biaoshi != 0" :underline="false" type="info">{{ item.mode }}</el-link>
              <el-select
                v-else
                clearable
                v-model="item.mode"
                placeholder="请选择"
                class="ele-fluid">
                <el-option label="总价比例%" value="proportion"/>
                <el-option label="固定金额元" value="immobilization"/>
              </el-select>
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              <el-link v-if="item.biaoshi != 0" :underline="false" type="info">{{ item.figure }}</el-link>
              <el-input
                v-else
                placeholder="请输入内容"
                v-model="item.figure"
                clearable>
              </el-input>
            </el-col>
            <el-col :span="6" style="border: 1px solid #ededf5;padding-top: 15px;padding-bottom: 15px;">
              <el-link :underline="false" type="warning" v-if="item.biaoshi == 0" @click="bupreservation(item,index)">保存</el-link>
              <el-link :underline="false" type="info" v-else @click="del1(item,index)">删除</el-link>
            </el-col>
          </el-row>
        </div>
      </el-row>



    </el-form>
    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
//服务项目接口
import {getget_services_available} from '@/api/yunli'
import {Addorder_audits, Detailsorder_audits, Editorder_audits, getConfig} from "@/api/yunli";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    id: Number
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {},
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      //服务项目
      ServicesAvailable:[],
    };
  },
  mounted() {
    // 判断是新增还是编辑
    if (this.data) {
      console.log(this.data)
      // this.form = Object.assign({}, this.data);
      this.form = {};
      this.isUpdate = true;
      //获取详情
      this.getDetails();
    } else {
      this.form = {};
      this.isUpdate = false;
      //获取配置
      this.Config();
    }

    // 获取服务项目
    this.getFuwuxiangmu();
  },
  methods: {
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    //获取配置
    Config(){
      getConfig().then(res => {
        console.log(res)
        this.form = res.data;
        this.form.assist.pass.forEach(item => {
          item.biaoshi = 0
        });
        this.form.assist.flunk.forEach(item => {
          item.biaoshi = 0
        });
      })
    },

    // 获取详情
    getDetails(){
      console.log('获取详情')
      Detailsorder_audits(this.id,this.data.id).then(res => {
        console.log(res)
        this.form = res.data;
        this.form.assist.pass.forEach(item => {
          item.biaoshi = 1
        });
        this.form.assist.flunk.forEach(item => {
          item.biaoshi = 1
        });
      })
    },

    /* 保存编辑 */
    save() {
      console.log(this.form);
      //判断是编辑还是新增
      if(this.isUpdate === false){
        Addorder_audits(this.id,this.form).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.$message.success(res.msg);
            if (!this.isUpdate) {
              this.form = {};
            }
            this.updateVisible(false);
            this.$emit('done');
          } else {
            this.$message.error(res.msg);
          }
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.msg);
        })
      }else {
        console.log(this.data);
        console.log(this.form);
        Editorder_audits(this.id,this.form.id,this.form).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.$message.success(res.msg);
            if (!this.isUpdate) {
              this.form = {};
            }
            this.updateVisible(false);
            this.$emit('done');
          } else {
            this.$message.error(res.msg);
          }
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.msg);
        })
      }

    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //点击自动核销
    active(){
      this.form.auto_affirm.switch = !this.form.auto_affirm.switch;
    },

    //点击完成订单自动审核合格
    automaticReviewactive(){
      this.form.order_auto_audit.switch = !this.form.order_auto_audit.switch;
    },

    //点击空驶订单自动审核合格
    kongshiactive(){
      this.form.empty_auto_audit.switch = !this.form.empty_auto_audit.switch;
    },

    //点击取消订单自动审核合格
    quxiaoactive(){
      this.form.cancellation_order.switch = ! this.form.cancellation_order.switch;
    },

    //审核合格补扣款项添加
    add(){
      //添加一条新数据
      this.form.assist.pass.push({
        indexes: "",
        name: "",
        mode: "",
        figure: "",
        biaoshi:0
      })
    },
    //点击保存按钮
    preservation(item,index){
      //更改标识
      item.biaoshi = 1;
      //将填写的数据添加到list数组
      this.form.assist.pass[index] = item;
      this.$forceUpdate();
    },
    //点击删除按钮
    del(item,index){
      this.form.assist.pass.splice(index, 1);
    },

    //审核不合格补扣款项添加
    buadd(){
      //添加一条新数据
      this.form.assist.flunk.push({
        indexes: "",
        name: "",
        mode: "",
        figure: "",
        biaoshi:0
      })
    },
    //点击保存按钮
    bupreservation(item,index){
      //更改标识
      item.biaoshi = 1;
      //将填写的数据添加到list数组
      this.form.assist.flunk[index] = item;
      this.$forceUpdate();
    },
    //点击删除按钮
    del1(item,index){
      this.form.assist.flunk.splice(index, 1);
    },

  }
}
</script>

<style scoped>
</style>
