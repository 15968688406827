<template>
  <!--订单审核配置-->
  <div>
    <div>
      <!--数据表格-->
      <el-card shadow="never">
        <div>
          <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)">
            <!--el-icon-devops 是自己命名的-->
            <i class="el-icon-tianjia" />
            <span style="vertical-align: middle">添加项目</span>
          </el-button>
        </div>
        <div class="card_b" style="margin-top: 20px;">
          <!-- 数据表格 -->
          <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :total="total"
            :customsFromWhere="where"
            :loading="loading"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
          >

            <!--自定义列显示 示例-->
            <template v-slot:auto_affirm= "{ scope }">
              <div>
                <img src="@/assets/images/home/yunli/duihao.png" alt="" style="width: 24px;height: 24px;" v-if="Object.keys(scope.row.auto_affirm) != 0">
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" style="width: 24px;height: 24px;" v-else>
              </div>
            </template>

            <template v-slot:order_auto_audit= "{ scope }">
              <div>
                <img src="@/assets/images/home/yunli/duihao.png" alt="" style="width: 24px;height: 24px;" v-if="Object.keys(scope.row.order_auto_audit) != 0">
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" style="width: 24px;height: 24px;" v-else>
              </div>
            </template>

            <template v-slot:empty_auto_audit= "{ scope }">
              <div>
                <img src="@/assets/images/home/yunli/duihao.png" alt="" style="width: 24px;height: 24px;" v-if="Object.keys(scope.row.empty_auto_audit) != 0">
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" style="width: 24px;height: 24px;" v-else>
              </div>
            </template>

            <template v-slot:cancellation_order= "{ scope }">
              <div>
                <img src="@/assets/images/home/yunli/duihao.png" alt="" style="width: 24px;height: 24px;" v-if="Object.keys(scope.row.cancellation_order).length !=0">
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" style="width: 24px;height: 24px;" v-else>
              </div>
            </template>

            <template v-slot:pass= "{ scope }">
              <div>
                {{scope.row.pass.figure}}
              </div>
            </template>

            <template v-slot:flunk= "{ scope }">
              <div>
                {{scope.row.flunk.figure}}
              </div>
            </template>

            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot= "{ scope }">
              <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openEdit(scope.row)">编辑</el-link>
              <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                <el-link slot="reference" :underline="false">删除</el-link>
              </el-popconfirm>
            </template>
          </my-table>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--审核设置弹窗-->
    <audit-settings-edit v-if="showEdit === true" :id="id" :data="current" :visible.sync="showEdit" @done="getList"/>

  </div>
</template>

<script>
//引入审核设置弹窗组件
import AuditSettingsEdit from './components/auditSettings-edit.vue'
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {Delorder_audits, getorder_audits} from "@/api/yunli";


export default {
  props:{
    id:Number
  },
  // 组件生效
  components: {
    PackageSortElement,
    AuditSettingsEdit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "订单类型",
          // type: "index",
          // width: "70"
          prop: "services_available",
          isShow: true
        },
        {
          label: "自动确认周期(小时)",
          prop: "automatic_period",
          isShow: true
        },
        {
          label: "补传照片周期(小时)",
          prop: "return_pass_period",
          isShow: true
        },
        {
          label: "自动核销",
          prop: "auto_affirm",
          slot: 'auto_affirm',
          isShow: true
        },
        {
          label: "完成订单",
          prop: "order_auto_audit",
          slot: 'order_auto_audit',
          isShow: true
        },
        {
          label: "空驶订单",
          prop: "empty_auto_audit",
          slot: 'empty_auto_audit',
          isShow: true
        },
        {
          label: "取消订单",
          prop: "cancellation_order",
          slot: 'cancellation_order',
          isShow: true
        },
        {
          label: "审核合格补扣款",
          prop: "pass",
          slot: 'pass',
          isShow: true
        },
        {
          label: "审核不合格补扣款",
          prop: "flunk",
          slot: 'flunk',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      current:null,
      showEdit:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getorder_audits(this.id,this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      // this.$utils.confirm("确定要删除吗？", function () {
      //   // 执行一些需要的逻辑
      //   console.log('点击确定')
      // }, "确定", 'warning');
      Delorder_audits(this.id,row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //打开审核设置弹窗
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
