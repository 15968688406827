import { render, staticRenderFns } from "./orderAllocation-edit.vue?vue&type=template&id=5fc7a444&scoped=true&"
import script from "./orderAllocation-edit.vue?vue&type=script&lang=js&"
export * from "./orderAllocation-edit.vue?vue&type=script&lang=js&"
import style0 from "./orderAllocation-edit.vue?vue&type=style&index=0&id=5fc7a444&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc7a444",
  null
  
)

export default component.exports