<!--服务商详情系统设置-->
<template>
  <div>
    <div class="title">
      <div class="title_t" style="margin-bottom: 0;">
        <span>系统设置</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <div class="tabpane">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="审核配置" name="first">
            <auditConfiguration :id="id" v-if="activeName == 'first'"/>
          </el-tab-pane>
          <el-tab-pane label="项目配置" name="second">
            <projectConfiguration :id="id" v-if="activeName == 'second'" />
          </el-tab-pane>
          <el-tab-pane label="示例图管理" name="third">
            <sampleImageManagement :id="id" v-if="activeName == 'third'"/>
          </el-tab-pane>
          <el-tab-pane label="拍照模板" name="fourth">
            <photoTemplate :id="id" v-if="activeName == 'fourth'" />
          </el-tab-pane>
          <el-tab-pane label="订单分配" name="fifth">
            <orderAllocation :id="id" v-if="activeName == 'fifth'" />
          </el-tab-pane>
          <el-tab-pane label="复制配置" name="sixth">
            <replicationConfiguration v-if="activeName == 'sixth'" />
          </el-tab-pane>
          <el-tab-pane label="其他配置" name="seventh">
            <otherConfigurations :id="id" v-if="activeName == 'seventh'"/>
          </el-tab-pane>
          <el-tab-pane label="自动结算配置" name="eighth">
            <automaticSettlementConfiguration :id="id" v-if="activeName == 'eighth'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>


  </div>
</template>

<script>
  //引入审核配置模块组件
  import auditConfiguration from './setUp/auditConfiguration.vue'
  //引入项目配置模块组件
  import projectConfiguration from './setUp/projectConfiguration.vue'
  //引入示例图管理模块组件
  import sampleImageManagement from './setUp/sampleImageManagement.vue'
  //引入拍照模板模块组件
  import photoTemplate from './setUp/photoTemplate.vue'
  //引入订单分配模块组件
  import orderAllocation from './setUp/orderAllocation.vue'
  //引入复制配置模块组件
  import replicationConfiguration from './setUp/replicationConfiguration.vue'
  //引入其他配置模块组件
  import otherConfigurations from './setUp/otherConfigurations.vue'
  //引入自动结算配置
  import automaticSettlementConfiguration from './setUp/automaticSettlementConfiguration.vue'


  export default {
    components:{
      auditConfiguration,
      projectConfiguration,
      sampleImageManagement,
      orderAllocation,
      replicationConfiguration,
      photoTemplate,
      otherConfigurations,
      automaticSettlementConfiguration
    },

    props:{
      id:Number
    },

    data(){
      return{
        activeName: 'first'
      }
    },


    methods:{
      //返回按钮
      returnPage(){
        this.$emit('systemSettingsshow', false);
      },

      //选项卡变化事件
      handleClick(tab, event) {
        console.log(tab, event);
      }

    },

  }
</script>

<style lang="scss" scoped>

</style>
