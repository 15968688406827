<template>
  <!--合同信息-->
  <div>
    <!--开票资料-->
    <el-row>
      <el-card shadow="never">
         <el-row :gutter="15">
           <el-col :lg="6" :md="12">
             <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">生效日期：</span>
             <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.start_time}}</span>
           </el-col>
           <el-col :lg="6" :md="12">
             <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">签约周期：</span>
             <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.period}}年</span>
           </el-col>
           <el-col :lg="6" :md="12">
             <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">到期自动签续：</span>
             <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.is_renew}}</span>
           </el-col>
           <el-col :lg="6" :md="12">
             <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">签约状态：</span>
             <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">
              <!--<span style="display: inline-block; width: 8px;height: 8px;background: #25eb61;border-radius: 50%; vertical-align: middle"></span>-->
              <span style="vertical-align: middle; margin-left: 5px;">{{shuju.status}}</span>
            </span>
           </el-col>
         </el-row>
      </el-card>

      <el-card shadow="never" style="margin-top: 20px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              签约记录
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <!--<el-button class="custom-button">-->
              <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--  <i class="el-icon-tianjia" />-->
              <!--  <span style="vertical-align: middle" @click="add()">新增开票资料</span>-->
              <!--</el-button>-->
              <el-button>垫资合同</el-button>
              <el-button @click="toUpdate()">更新签约</el-button>
            </div>
          </div>
        </div>

        <!--数据表格-->
        <div class="dataList" style="margin-top: 20px;">
          <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :total="total"
            :customsFromWhere="where"
            :loading="loading"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
          >
            <!--签约周期-->
            <template v-slot:period= "{ scope }">
              <span>{{scope.row.period}} 年</span>
            </template>

            <!--到期自动续签-->
            <template v-slot:is_renew= "{ scope }">
              <span v-if="scope.row.is_renew == 0" style="color: #36DD9E;">是</span>
              <span v-else>否</span>
            </template>

            <!--合同附件-->
            <template v-slot:agreement= "{ scope }">
              <span style="color: #FF9E1C;cursor: pointer;" @click="open(scope.row.agreement)">查看</span>
            </template>


            <!-- 不使用数据可不接收scope值 -->
            <!--<template v-slot:operationSlot= "{ scope }">-->
            <!--  <el-link :underline="false" type="warning">查看</el-link>-->
            <!--</template>-->
            <!--自定义列显示 示例-->
            <template v-slot:means_payments= "{ scope }">
              <div v-for="(item) in dict.type.valuationclearing_form">
                <span v-if="scope.row.means_payments == item.value">{{item.label}}</span>
              </div>
            </template>
          </my-table>
        </div>
      </el-card>
    </el-row>

    <!--更新签约弹窗-->
    <SubscriptionInfo v-if="showsignAContract" :id="id" :dict="dict" :data="signAContractcurrent" :visible.sync="showsignAContract" @done="getList"/>

    <!--合同附件-->
    <el-dialog
        center
        title="合同附件"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <div>
        <div v-for="(item,index) in appendixtothecontract" :key="index" style="cursor: pointer;margin-bottom: 10px;" @click="fileshow(item)">
          {{item}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!--<el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      </span>
    </el-dialog>
    <!--查看-->
    <el-dialog
        center
        :visible.sync="dialogVisible1"
        width="30%"
        :before-close="handleClose1">
      <div>
        查看
      </div>
    </el-dialog>


  </div>
</template>

<script>
//引入更新签约弹窗
import SubscriptionInfo from './components/subscription-info.vue'

// 引入的接口
import {
   getreconciliation
} from '@/api/yunli'
// 权限
import {ShowTable} from '@/utils'
import Vue from "vue";

export default {
  props: {
    // id
    id:Number
  },
  components:{
    SubscriptionInfo
  },
  data(){
    return{
      // 搜索条件
      where:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "生效日期",
          prop: "end_time",
          isShow: true
        },
        {
          label: "签约周期",
          prop: "period",
          slot:'period',
          isShow: true
        },
        {
          label: "到期自动续签",
          prop: "is_renew",
          slot:'is_renew',
          isShow: true
        },
        {
          label: "合同附件",
          prop: "agreement",
          slot:'agreement',
          isShow: true
        },
        {
          label: "操作时间",
          prop: "operating_time",
          isShow: true
        },
        {
          label: "操作人",
          prop: "operating_user",
          isShow: true
        },
        {
          label: "结算方式",
          width: "200",
          prop: 'means_payments',
          slot: "means_payments",
          isShow: true
        },
      ],
      //开票资料
      current:null,
      showEdit:false,

      //更新签约
      signAContractcurrent:null,
      showsignAContract:false,
      //合同附件
      dialogVisible:false,
      appendixtothecontract:[],
      dialogVisible1:false,

      shuju:{},

      //字典
      dict: {
        type:{}
      },

    }
  },

  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '客户字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    this.getList();
  },

  methods:{
    // 点击编辑按钮
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    // 获取列表
    getList(){
      getreconciliation(this.id,this.where).then(async (res) => {
        console.log('合同信息列表',res);
        //签约记录
        // this.total1 = res.data.count;
        this.shuju = res.data;
        this.List = res.data.contract_award;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },


    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },


    //更新签约
    toUpdate(){
      this.showsignAContract = true;
    },

    //合同附件查看
    open(row){
      console.log(row);
      this.appendixtothecontract = row;
      this.dialogVisible = true;
    },
    // 合同附件关闭
    handleClose(){
      this.dialogVisible = false;
    },
    fileshow(item){
      // this.dialogVisible1 = true;
      // this.dialogVisible = false;
    },
    // 合同附件查看关闭
    handleClose1(){
      this.dialogVisible1 = false;
      this.dialogVisible = true;
    },


  }

}
</script>

<style lang="scss" scoped>
.content{

}
.dataList{
  margin-top: 10px;
}
</style>
