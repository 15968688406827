<!-- 更新签约信息弹窗 -->
<template>
  <div>
    <el-dialog
        width="30%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'开票申请':'开票申请'"
        @update:visible="updateVisible">

      <el-form
          ref="form"
          :model="form"
          label-width="100px">
        <el-form-item label="选择开票信息">
          <div style="display: flex;align-items: center;">
            <el-select
                clearable
                v-model="form.status"
                placeholder="请选择"
                class="ele-fluid">
              <el-option label="1级" value="1"/>
              <el-option label="2级" value="2"/>
              <el-option label="3级" value="3"/>
            </el-select>
            <el-button style="margin-left: 20px;">管理开票信息</el-button>
          </div>
        </el-form-item>
        <el-form-item label="开票金额">
          <div style="display: flex;align-items: center;">
            <el-input
                style="width: 60%!important;"
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入开票金额"/>
            <span style="margin-left: 20px;">可开票 2000</span>
          </div>
        </el-form-item>

      </el-form>

      <div slot="footer" >
        <!--<el-button-->
        <!--    @click="updateVisible(false)">取消-->
        <!--</el-button>-->
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="updateVisible(false)">申请开票
        </el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
