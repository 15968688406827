<!-- 运营添加示例图-->
<template>
  <div>
    <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'编辑示例图':'添加示例图'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px">
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="示例图名称" prop="sample_graph_name">
              <el-input
                placeholder="请输入示例图名称"
                v-model="form.sample_graph_name"
                clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="拍照要求:" prop="claim">
              <el-input
                placeholder="请输入拍照要求"
                v-model="form.claim"
                clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="拍照图例">
              <div class="upload_bg_shouchi">
                <el-upload
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload"
                  :limit="1"
                  :file-list="fileList"
                  :class="{hide_box: handupload_btn}"
                  :on-change="handchange"
                  list-type="picture-card"
                  :on-preview="handPreview"
                  :on-remove="handhandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 10px;">上传营业执照</div>
                  <div style="font-size: 12px;font-weight: 400;color: #c1c2ce;margin-top: 20px;">支持jpg，jpeg，png格式，最大10M</div>
                </el-upload>
                <el-dialog :visible.sync="handdialogVisible">
                  <img width="100%" :src="handdialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save('form')">确定
        </el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import {Addsamples, Editsamples} from "@/api/yunli";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    id: Number
  },
  components: {

  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        sample_graph_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        claim: [
          { required: true, message: '请输入拍照要求', trigger: 'blur' },
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/operate',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      //营业执照照片
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,
      fileList:[],

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
        let where = {
          url: this.data.sample_graph_path
        }
        this.handupload_btn = true;
        this.fileList.push(where);
      } else {
        this.form = {};
        this.handdialogImageUrl = '';
        this.handdialogVisible = false;
        this.handupload_btn = false;
        this.fileList = [];
        this.isUpdate = false;
      }
    }
  },
  mounted() {
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        console.log(this.form);
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            Addsamples(this.id,this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editsamples(this.id,data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


    //营业执照照片事件
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.fileList = [];
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.handdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.sample_graph_path = file.data.urls;
    },


  }
}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
