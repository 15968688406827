<!-- 运营示例图配置 -->
<template>
  <div>
    <el-card shadow="never" style="margin-top: 20px;">
      <el-row :gutter="15">
        <el-col :span="12" style="display: flex;">
          <el-input
            placeholder="编号/示例图名称"
            v-model="where.name"
            clearable>
          </el-input>
          <el-button type="primary" icon="el-icon-search" style="background: #EBECF2!important;border-color: #EBECF2!important;color: #3F4157!important;margin-left: 20px;">搜索</el-button>
        </el-col>
        <el-col :span="12" style="display: flex;justify-content: flex-end;">
          <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;" @click="openEdit(null)">
            <i class="el-icon-tianjia" />
            <span style="vertical-align: middle">添加示例图</span>
          </el-button>
        </el-col>
      </el-row>

      <el-row :gutter="15" style="margin-top: 20px;">
        <el-col :span="6" v-loading="loading" v-for="(item,index) in List" :key="index" style="margin-bottom: 20px;cursor: pointer;">
          <div style="border: 1px solid #e3e4e9;border-radius: 6px;">
            <img :src="item.sample_graph_path" alt="" style="height: 200px;width: 100%">
            <div style="padding: 10px 20px 10px 20px;">
              <div style="font-size: 16px;font-weight: 700;color: #242832;">{{item.sample_graph_name}}</div>
              <div class="chaochu" style="font-size: 12px;font-weight: 400;color: #7e7f8c;margin-top: 15px;height: 36px;">{{item.claim}}</div>
              <el-row :gutter="15" style="margin-top: 20px;">
                <el-col :span="12">
                  <el-popconfirm title="确定执行此操作吗？" @confirm="del(item)">
                    <span slot="reference" style="width: 100%;background: #f7f8fa;border: 1px solid #e3e4e9;border-radius: 6px;padding-top: 10px;padding-bottom: 10px;font-size: 16px;font-weight: 500;color: #242832;text-align: center;display: inline-block;">删除</span>
                  </el-popconfirm>
                </el-col>
                <el-col :span="12">
                  <span style="width: 100%;background: #FFFAE9;border: 1px solid #FF9B05;border-radius: 6px;padding-top: 10px;padding-bottom: 10px;font-size: 16px;font-weight: 500;color: #FF9B05;text-align: center;display: inline-block;" @click="openEdit(item)">编辑</span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>

      <div v-if="total == 0">
        <el-empty description="数据为空"></el-empty>
      </div>

      <el-pagination
        style="text-align: center; margin-top: 20px"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="where.limit"
        :current-page="where.page"
        @size-change="ChangeSize"
        @current-change="currentChange"
        background>
      </el-pagination>
    </el-card>


    <!--添加示例图弹窗组件-->
    <addSampleImage-edit :id="id" :data="current" :visible.sync="showEdit" @done="getList"/>

  </div>
</template>

<script>
// 引入添加示例图弹窗组件
import addSampleImageEdit from './components/addSampleImage-edit.vue'
// 添加接口
import {Delsamples, getsamples} from "@/api/yunli";

export default {
  props:{
    id:Number
  },
  components: {addSampleImageEdit},
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 总条目数
      total: 0,
      //加载
      loading:false,

      List:[],

      //添加示例图
      current:null,
      showEdit:false,

    }
  },

  mounted() {
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getsamples(this.id,this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        console.log(this.List);
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },


    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    //删除
    del(item){
      Delsamples(this.id,item.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    //显示编辑弹窗
    openEdit(item){
      this.current = item;
      this.showEdit = true;
    },


  }

}
</script>

<style lang="scss" scoped>
.chaochu{
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
