<template>
  <!--自动结算配置-->
  <div>
    <el-card shadow="never">
      <el-row :gutter="15">
        <el-col :span="24">
          <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;" @click="add()">
            <i class="el-icon-tianjia" />
            <span style="vertical-align: middle">添加行</span>
          </el-button>
        </el-col>
      </el-row>
      <!--数据表格-->
      <div style="margin-top: 20px;">
        <my-table
          ref="myTable"
          :columns="columns"
          :tableData="List"
          :cellStyle="cellStyle"
          :headerCellStyle="headerCellStyle"
          :rowClassName="rowClassName"
          :total="total"
          :customsFromWhere="where"
          :loading="loading"
          @select="select"
          @ChangeSize="ChangeSize"
          @currentChange="currentChange"
        >
          <!--自定义列显示 示例-->
          <template v-slot:name= "{ scope }">
            <div v-if="scope.row.index == 0">
              <span>{{scope.row.name}}</span>
            </div>
            <div v-else>
              <el-select
                clearable
                v-model="scope.row.items_number"
                placeholder="请选择"
                class="ele-fluid">
                <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
              </el-select>
            </div>
          </template>

          <template v-slot:order_audit_picture_qualified= "{ scope }">
            <div v-if="scope.row.index == 0">
              <div v-if="scope.row.order_audit_picture_qualified == 0">
                <img src="@/assets/images/home/yunli/duihao.png" alt="" style="width: 24px;height: 24px;">
              </div>
              <div v-else>
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" style="width: 24px;height: 24px;">
              </div>
            </div>
            <div v-else>
              <el-select
                clearable
                v-model="scope.row.order_audit_picture_qualified"
                placeholder="请选择"
                class="ele-fluid">
                <el-option label="是" :value="0"/>
                <el-option label="否" :value="1"/>
              </el-select>
            </div>
          </template>

          <template v-slot:no_contract_price= "{ scope }">
            <div v-if="scope.row.index == 0">
              <div v-if="scope.row.no_contract_price == 0">
                <img src="@/assets/images/home/yunli/duihao.png" alt="" style="width: 24px;height: 24px;">
              </div>
              <div v-else>
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" style="width: 24px;height: 24px;">
              </div>
            </div>
            <div v-else>
              <el-select
                clearable
                v-model="scope.row.no_contract_price"
                placeholder="请选择"
                class="ele-fluid">
                <el-option label="是" :value="0"/>
                <el-option label="否" :value="1"/>
              </el-select>
            </div>
          </template>

          <template v-slot:no_starting_price= "{ scope }">
            <div v-if="scope.row.index == 0">
              <div v-if="scope.row.no_starting_price == 0">
                <img src="@/assets/images/home/yunli/duihao.png" alt="" style="width: 24px;height: 24px;">
              </div>
              <div v-else>
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" style="width: 24px;height: 24px;">
              </div>
            </div>
            <div v-else>
              <el-select
                clearable
                v-model="scope.row.no_starting_price"
                placeholder="请选择"
                class="ele-fluid">
                <el-option label="是" :value="0"/>
                <el-option label="否" :value="1"/>
              </el-select>
            </div>
          </template>

          <!-- 不使用数据可不接收scope值 -->
          <template v-slot:operationSlot= "{ scope }">
            <el-link :underline="false" type="warning" v-if="scope.row.index == 0" @click="edit(scope.row,scope.$index)">编辑</el-link>
            <el-link :underline="false" type="info" v-if="scope.row.index == 0" @click="del(scope.row,scope.$index)">删除</el-link>
            <el-link :underline="false" type="warning" v-else @click="save(scope.row,scope.$index)">保存</el-link>
          </template>
        </my-table>
      </div>
    </el-card>

  </div>
</template>

<script>
// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {Addauto_balances, Delauto_balances, Editauto_balances, getauto_balances} from "@/api/yunli";
import {getget_services_available} from "@/api/yunli";

export default {
  props:{
    id:Number
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        // {
        //   type: "selection",
        //   isShow: true
        // },
        {
          label: "项目类型",
          width: "200",
          prop: "name",
          slot: "name",
          isShow: true,
          fixed: 'left'
        },
        {
          label: '订单照片审核全部合格',
          prop: "order_audit_picture_qualified",
          slot: "order_audit_picture_qualified",
          isShow: true,
        },
        {
          label: '没有合作合同价格',
          prop: "no_contract_price",
          slot: "no_contract_price",
          isShow: true,
        },
        {
          label: '没有超出起步价格',
          prop: "no_starting_price",
          slot: "no_starting_price",
          isShow: true,
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      //服务项目
      ServicesAvailable:[],

      // 编辑还是新增
      isUpdata:false,

    }
  },

  mounted() {
    this.getFuwuxiangmu();
    this.getList();
  },

  methods:{
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    // 获取列表
    getList(){
      getauto_balances(this.id,this.where).then(async (res) => {
        // this.total = res.data.count;
        this.List = res.data;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },

    // 点击添加行
    add(){
      let that = this;
      console.log(that.List);
      this.List.push({
        items_number:'',
        order_audit_picture_qualified:0,
        no_contract_price:0,
        no_starting_price:0,
        index:1,
      })
      this.isUpdata = false;
    },
    //点击保存
    save(row,index){
      console.log(row);
      if(row.items_number == ''){
        this.$message.error('请选择项目')
      }else {
        //更改标识
        row.index = 0;
        if(this.isUpdata == false){
          console.log(this.isUpdata)
          Addauto_balances(this.id,row).then(res => {
            if(res.code == 200){
              this.$message.success(res.msg);
              this.getList();
            }else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.List.splice(index,1)
            this.$message.error(e.msg)
          })
        }else {
          Editauto_balances(this.id,row.id,row).then(res => {
            console.log(res)
            if(res.code === 200){
              this.$message.success(res.msg);
              this.getList();
            }else {
              this.$message.success(res.msg);
            }
          }).catch(e => {
            this.$message.error(e.msg)
          })
        }
      }
    },

    // 编辑
    edit(row){
      console.log(row);
      row.index = 1;
      this.isUpdata = true;
      //强制更新视图
      this.$forceUpdate();
    },

    //点击删除
    del(row,index){
      console.log(row)
      console.log(index)
      //删除数组元素
      // this.List.splice(index,1);
      let that = this;
      // Delauto_balances
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        Delauto_balances(this.id,row.id).then(res => {
          console.log(res)
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.success(res.msg);
          }
        }).catch(e => {
          that.$message.error(e.msg)
        })
      }, "确定", 'warning');
    },


  },


}
</script>

<style lang="scss" scoped>

</style>
