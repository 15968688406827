import request from '@/utils/request'

/**
 * 人力 员工管理
 * @returns {*}
 */
export function getstaff (params) {
  return request({
    url: 'manpower/staffs',
    method: 'get',
    params
  })
}

/**
 * 人力 员工管理 员工详情接口
 * @returns {*}
 */
export function staff (id,params) {
  return request({
    url: `manpower/staffs/${id}`,
    method: 'get',
    params
  })
}

/**
 * 人力 员工管理 状态修改
 * @returns {*}
 */
export function setstatus (id,params) {
  return request({
    url: `manpower/staff/status/${id}`,
    method: 'get',
    params
  })
}

/**
 * 人力 员工管理 添加
 * @returns {*}
 */
export function Addstaff (data) {
  return request({
    url: `manpower/staffs`,
    method: 'post',
    data
  })
}
/**
 *  人力 员工管理 编辑
 * @returns {*}
 */
export function Editstaff (id,data) {
  return request({
    url: `manpower/staffs/${id}`,
    method: 'put',
    data
  })
}

/**
 *   人力 员工管理 删除
 * @returns {*}
 */
export function staffdelete (id) {
  return request({
    url: `manpower/staffs/${id}`,
    method: 'delete',
  })
}


/**
 * 人力 员工管理 获取格式化后的部门
 * @returns {*}
 */
export function getget_format (params) {
  return request({
    url: 'manpower/department/get_format',
    method: 'get',
    params
  })
}

/**
 * 人力 薪酬管理
 * @returns {*}
 */
export function getremunerations (params) {
  return request({
    url: 'manpower/remunerations',
    method: 'get',
    params
  })
}
/**
 * 人力 薪酬管理获取配置
 * @returns {*}
 */
export function getconfig (params) {
  return request({
    url: 'manpower/remuneration/config',
    method: 'get',
    params
  })
}
/**
 * 人力 薪酬管理 详情
 * @returns {*}
 */
export function Detailsremunerations (id,params) {
  return request({
    url: `manpower/remunerations/${id}`,
    method: 'get',
    params
  })
}

/**
 * 人力 薪酬管理 添加
 * @returns {*}
 */
export function Addremunerations (data) {
  return request({
    url: `manpower/remunerations`,
    method: 'post',
    data
  })
}
/**
 *  人力 薪酬管理 编辑
 * @returns {*}
 */
export function Editremunerations (id,data) {
  return request({
    url: `manpower/remunerations/${id}`,
    method: 'put',
    data
  })
}

/**
 *   人力 薪酬管理 删除
 * @returns {*}
 */
export function Delremunerations (id) {
  return request({
    url: `manpower/remunerations/${id}`,
    method: 'delete',
  })
}


/**
 * 人力 部门管理
 * @returns {*}
 */
export function getdepartments (params) {
  return request({
    url: 'manpower/departments',
    method: 'get',
    params
  })
}
/**
 * 人力 部门管理 新增
 * @returns {*}
 */
export function Adddepartments (data) {
  return request({
    url: `manpower/departments`,
    method: 'post',
    data
  })
}
/**
 *  人力 部门管理 编辑
 * @returns {*}
 */
export function Editdepartments (id,data) {
  return request({
    url: `manpower/departments/${id}`,
    method: 'put',
    data
  })
}
/**
 * 人力 部门管理 获取格式化后的部门接口
 * @returns {*}
 */
export function get_format (params) {
  return request({
    url: 'manpower/department/get_format',
    method: 'get',
    params
  })
}
/**
 * 人力 部门管理 详情
 * @returns {*}
 */
export function Detailsdepartments (id,params) {
  return request({
    url: `manpower/departments/${id}`,
    method: 'get',
    params
  })
}
/**
 *   人力 部门管理 删除
 * @returns {*}
 */
export function Deldepartments (id) {
  return request({
    url: `manpower/departments/${id}`,
    method: 'delete',
  })
}



/**
 * 人力 角色管理
 * @returns {*}
 */
export function getroles (params) {
  return request({
    url: '/system/roles',
    method: 'get',
    params
  })
}
/**
 * 人力 角色管理 新增
 * @returns {*}
 */
export function Addroles (data) {
  return request({
    url: `/system/roles`,
    method: 'post',
    data
  })
}
/**
 *  人力 角色管理 编辑
 * @returns {*}
 */
export function Editroles (id,data) {
  return request({
    url: `/system/roles/${id}`,
    method: 'put',
    data
  })
}
/**
 *   人力 角色管理 删除
 * @returns {*}
 */
export function Delroles (id) {
  return request({
    url: `/system/roles/${id}`,
    method: 'delete',
  })
}
/**
 * 人力 角色管理 角色用户列表
 * @returns {*}
 */
export function getwith_facilitator (id,params) {
  return request({
    url: `manpower/role/with_facilitator/${id}`,
    method: 'get',
    params
  })
}


/**
 * 角色 获取树形菜单列表接口
 * @returns {*}
 */
export function cascader_list (params) {
  return request({
    url: '/system/roles_group/menus',
    method: 'get',
    params
  })
}

/**
 * 角色 获取关联的菜单ID接口
 * @returns {*}
 */
export function get_relevancy (id,params) {
  return request({
    url: `/system/roles_group/get_relevancy/${id}`,
    method: 'get',
    params
  })
}

/**
 * 角色 菜单关联接口
 * @returns {*}
 */
export function relevancy_menus (id,data) {
  return request({
    url: `/system/roles_group/relevancy_menus/${id}`,
    method: 'post',
    data
  })
}


/**
 * 获取角色下拉列表接口
 * @returns {*}
 */
export function role_select (params) {
  return request({
    url: '/system/roles_group/role_select',
    method: 'get',
    params
  })
}
