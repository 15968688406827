<!-- 更新签约信息弹窗 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'更新签约信息':'更新签约信息'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="80px">
      <el-row :gutter="18">
        <el-col :span="24">
          <el-form-item label="生效日期:" prop="commencement_date">
            <el-date-picker
                v-model="form.commencement_date"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="签约周期:" prop="period">
            <el-input placeholder="请输入" v-model="form.period">
              <template slot="append">.年</template>
            </el-input>
          </el-form-item>
          <el-form-item label="自动续签:" prop="is_renew">
            <el-select
                clearable
                v-model="form.is_renew"
                placeholder="请选择"
                class="ele-fluid">
              <el-option label="是" value="0"/>
              <el-option label="否" value="1"/>
            </el-select>
          </el-form-item>
          <el-form-item label="结算方式:" prop="means_payments">
            <el-select
                clearable
                v-model="form.means_payments"
                placeholder="请选择"
                class="ele-fluid">
              <el-option
                  v-for="(item) in dict.type.valuationclearing_form"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <!--上传合同附件-->
      <el-row :gutter="18">
        <div class="zidingyishangchuan">
          <el-col :span="24" style="display: flex">
            <span style="font-size: 14px;font-weight: 700;text-align: right;color: #4e4e4e; margin-top: 8px;">上传合同附件：</span>
            <el-upload
                class="upload-demo"
                :action="action"
                :headers="headers"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-success="successUpload"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :file-list="fileList">
              <div class="shangchuan" style="margin-left: 20px">
                <el-button class="custom-button">
                  <i class="el-icon-shangchuan" />
                  <span style="vertical-align: middle">点击上传</span>
                </el-button>
              </div>
              <div slot="tip" class="el-upload__tip" style="margin-left: 20px;">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-col>
        </div>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {save_contract_award} from "@/api/yunli";

// 获取配置
import setting from '@/config/setting'
import {getCookies, setCookies} from '@/utils/cookies'
import Config from '@/config'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // 父级id
    id: Number
  },
  components: { },
  data() {
    return {
      // 上传假数据展示
      fileList: [],
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/contract',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    // 新增修改都需要添加这个id
    this.form.facilitator_id = this.id;

    console.log('上传接口',this.action);

    console.log('请求头',this.headers);

    // 每次上传前为空
    this.form.agreement = [];

  },
  methods: {
    //上传事件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      // 上传成功后的列表
      this.form.agreement.push(file.data.urls);
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(this.form)
          this.form.channel_id = this.form.id;
          delete this.form.id
          save_contract_award(this.id,this.form).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped>
</style>
