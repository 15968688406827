<!--短信设置-->
<template>
  <div>
    <el-card shadow="never">
      <el-form ref="form" :model="form" label-width="300px">
        <div style="padding: 0 20px 0 20px;">
          <div>
            <el-checkbox v-model="checked" @change="handleCheckAllChange">全选</el-checkbox>
          </div>
          <div style="margin-top: 10px;" v-for="(item,index) in List" :key="index">
            <div style="display: flex;align-items: center;">
              <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 20px;font-weight: 700;color: #050505;">{{item.title}}</span>
            </div>
            <div style="margin-top: 15px;">
              <!--<el-checkbox v-for="(it) in item.data" v-model="it.is_switch">-->
              <!--  <span style="margin-right: 20px;">{{it.label}}</span>-->
              <!--  <el-input placeholder="" v-if="it.value != undefined" v-model="it.value" style="margin-left: 10px!important;margin-bottom: 10px;">-->
              <!--    <template slot="append">秒</template>-->
              <!--  </el-input>-->
              <!--</el-checkbox>-->
              <!--<div v-for="(it) in item.data">-->
              <!--  <div v-if="it.label == '是否自动发送客户查单链接' || it.label == '是否自动发送司机接单连接'">-->
              <!--    <span>{{it.label}}</span>-->
              <!--    <el-switch  v-model="it.is_switch"></el-switch>-->
              <!--  </div>-->
              <!--</div>-->

              <span v-for="(it) in item.data" v-model="it.is_switch">
                <span v-if="it.label == '是否自动发送客户查单链接' || it.label == '是否自动发送司机接单连接'" style="margin-right: 10px;">
                  <span style="margin-right: 10px;">{{it.label}}</span>
                  <el-switch  v-model="it.is_switch"></el-switch>
                </span>
                <span style="margin-right: 100px;" v-else>
                  <el-checkbox v-model="it.is_switch">
                    <span style="margin-right: 20px;">{{it.label}}</span>
                    <el-input placeholder="" v-if="it.value != undefined" v-model="it.value" style="margin-left: 10px!important;margin-bottom: 10px;">
                      <template slot="append">秒</template>
                    </el-input>
                  </el-checkbox>
                </span>
              </span>

            </div>
          </div>

          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="display: flex;align-items: center;">-->
          <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
          <!--    <span style="font-size: 20px;font-weight: 700;color: #050505;">派单时</span>-->
          <!--  </div>-->
          <!--  <div style="margin-top: 15px;">-->
          <!--    <el-checkbox-group v-model="checkList">-->
          <!--      <el-checkbox label="开通短信提醒"></el-checkbox>-->
          <!--      <el-checkbox label="提醒调度人员"></el-checkbox>-->
          <!--      <el-checkbox label="提醒被救援人"></el-checkbox>-->
          <!--      <el-checkbox label="提醒司机/服务商"></el-checkbox>-->
          <!--      <el-checkbox label="自动语音催单司机/服务商"></el-checkbox>-->
          <!--    </el-checkbox-group>-->
          <!--  </div>-->
          <!--</div>-->

          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="display: flex;align-items: center;">-->
          <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
          <!--    <span style="font-size: 20px;font-weight: 700;color: #050505;">到达现场</span>-->
          <!--  </div>-->
          <!--  <div style="margin-top: 15px;">-->
          <!--    <el-checkbox-group v-model="checkList">-->
          <!--      <el-checkbox label="开通短信提醒"></el-checkbox>-->
          <!--      <el-checkbox label="提醒调度人员"></el-checkbox>-->
          <!--      <el-checkbox label="提醒被救援人"></el-checkbox>-->
          <!--    </el-checkbox-group>-->
          <!--  </div>-->
          <!--</div>-->

          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="display: flex;align-items: center;">-->
          <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
          <!--    <span style="font-size: 20px;font-weight: 700;color: #050505;">到达目的地</span>-->
          <!--  </div>-->
          <!--  <div style="margin-top: 15px;">-->
          <!--    <el-checkbox-group v-model="checkList">-->
          <!--      <el-checkbox label="开通短信提醒"></el-checkbox>-->
          <!--      <el-checkbox label="提醒调度人员"></el-checkbox>-->
          <!--      <el-checkbox label="提醒被救援人"></el-checkbox>-->
          <!--    </el-checkbox-group>-->
          <!--  </div>-->
          <!--</div>-->

          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="display: flex;align-items: center;">-->
          <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
          <!--    <span style="font-size: 20px;font-weight: 700;color: #050505;">服务完成</span>-->
          <!--  </div>-->
          <!--  <div style="margin-top: 15px;">-->
          <!--    <el-checkbox-group v-model="checkList">-->
          <!--      <el-checkbox label="开通短信提醒"></el-checkbox>-->
          <!--      <el-checkbox label="提醒调度人员"></el-checkbox>-->
          <!--      <el-checkbox label="提醒被救援人"></el-checkbox>-->
          <!--    </el-checkbox-group>-->
          <!--  </div>-->
          <!--</div>-->

          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="display: flex;align-items: center;">-->
          <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
          <!--    <span style="font-size: 20px;font-weight: 700;color: #050505;">客户投诉</span>-->
          <!--  </div>-->
          <!--  <div style="margin-top: 15px;">-->
          <!--    <el-checkbox-group v-model="checkList">-->
          <!--      <el-checkbox label="开通短信提醒"></el-checkbox>-->
          <!--      <el-checkbox label="提醒调度人员"></el-checkbox>-->
          <!--      <el-checkbox label="提醒被救援人"></el-checkbox>-->
          <!--      <el-checkbox label="提醒司机/服务商"></el-checkbox>-->
          <!--    </el-checkbox-group>-->
          <!--  </div>-->
          <!--</div>-->

          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="display: flex;align-items: center;">-->
          <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
          <!--    <span style="font-size: 20px;font-weight: 700;color: #050505;">自动语音催单</span>-->
          <!--  </div>-->
          <!--  <div style="margin-top: 15px;">-->
          <!--    <el-row :gutter="15" style="display: flex;align-items: center;">-->
          <!--      <el-col :span="4">-->
          <!--        <el-checkbox v-model="form.checked">开通自动语音提醒</el-checkbox>-->
          <!--      </el-col>-->
          <!--      <el-col :span="4">-->
          <!--        <div style="display: flex;align-items: center;">-->
          <!--          <el-checkbox v-model="form.checked">开通自动语音提醒</el-checkbox>-->
          <!--          <el-input placeholder="" v-model="form.input2" style="margin-left: 10px!important;">-->
          <!--            <template slot="append">秒</template>-->
          <!--          </el-input>-->
          <!--        </div>-->
          <!--      </el-col>-->
          <!--      <el-col :span="4">-->
          <!--        <div style="display: flex;align-items: center;">-->
          <!--          <el-checkbox v-model="form.checked">接单催促</el-checkbox>-->
          <!--          <el-input placeholder="" v-model="form.input2" style="margin-left: 10px!important;">-->
          <!--            <template slot="append">秒</template>-->
          <!--          </el-input>-->
          <!--        </div>-->
          <!--      </el-col>-->
          <!--      <el-col :span="4">-->
          <!--        <div style="display: flex;align-items: center;">-->
          <!--          <el-checkbox v-model="form.checked">到达催促</el-checkbox>-->
          <!--          <el-input placeholder="" v-model="form.input2" style="margin-left: 10px!important;">-->
          <!--            <template slot="append">秒</template>-->
          <!--          </el-input>-->
          <!--        </div>-->
          <!--      </el-col>-->
          <!--      <el-col :span="4">-->
          <!--        <div style="display: flex;align-items: center;">-->
          <!--          <el-checkbox v-model="form.checked">完成催促</el-checkbox>-->
          <!--          <el-input placeholder="" v-model="form.input2" style="margin-left: 10px!important;">-->
          <!--            <template slot="append">秒</template>-->
          <!--          </el-input>-->
          <!--        </div>-->
          <!--      </el-col>-->
          <!--    </el-row>-->
          <!--  </div>-->
          <!--</div>-->

          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="display: flex;align-items: center;">-->
          <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
          <!--    <span style="font-size: 20px;font-weight: 700;color: #050505;">发短信</span>-->
          <!--  </div>-->
          <!--  <div style="margin-top: 15px;">-->
          <!--    <el-checkbox-group v-model="checkList">-->
          <!--      <el-checkbox label="自动发送客户查单链接"></el-checkbox>-->
          <!--      <el-checkbox label="自动发送司机接单链接"></el-checkbox>-->
          <!--      <el-checkbox label="手动发送客户查单链接"></el-checkbox>-->
          <!--      <el-checkbox label="提醒司机/手动发送司机接单链接"></el-checkbox>-->
          <!--    </el-checkbox-group>-->
          <!--  </div>-->
          <!--</div>-->

        </div>
      </el-form>
    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button
          style="margin-left: 20px;width: 240px;"
          type="primary"
          @click="save()">提交
      </el-button>
    </div>

  </div>
</template>

<script>
import {get_note_config, Saveeset_note_config} from "@/api/yunli";

export default {
  props:{
    id:Number
  },
  data(){
    return{
      form:{},

      List:[],

      // 全选
      checked: false,

    }
  },

  mounted() {
    this.getConfig();
  },

  methods:{
    //获取
    getConfig(){
      get_note_config(this.id).then(res => {
        console.log(res);
        this.List = res.data;
      })
    },

    //点击全选
    handleCheckAllChange(){
      if(this.checked === true){
        this.List.forEach(item => {
          item.data.forEach(res => {
            res.is_switch = true;
          })
        })
      }else {
        this.List.forEach(item => {
          item.data.forEach(res => {
            res.is_switch = false;
          })
        })
      }
    },

    //保存
    save(){
      let where = {
        data: this.List
      }
      Saveeset_note_config(this.id,where).then(res => {
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getConfig();
        }else {
          this.$message.error(e.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

  },

}
</script>

<style lang="scss" scoped>

</style>
